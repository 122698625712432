import { Gender, LanguageLevel, MaritalStatus } from '@/accountSetup/accountSetupService';
import * as axios from 'axios';
import { AssistantListItem } from './assistantsWebServices';
import qs from 'qs'

const url = `${process.env.VUE_APP_API_URL}/search`;

export interface SearchLanguageModel {
    language?: string;
    level?: LanguageLevel
}
export interface SearchAssistantModel {
    pageSize?: number;
    pageNumber?: number;

    phrase?: string;
    hobbies: string[];
    interests: string[];
    categories: string[];
    categoryGroups: string[];
    languages: SearchLanguageModel[];
    countryCode?: string;
    cityId?: string;
    regionId?: string;
    ageFrom?: number;
    ageTo?: number;
    gender?: Gender;
    maritalStatus?: MaritalStatus;
    heightFrom?: number;
    heightTo?: number;
    hourlyRateFrom?: number;
    hourlyRateTo?: number;
}

export function searchForAssistants(model: SearchAssistantModel) {
    const params: { [id: string]: any } = {
        ...model,
        languages: model.languages && model.languages.length ? model.languages.map((x) => x.language ?? '') : [],
        languageLevels: model.languages && model.languages.length ? model.languages.map((x) => x.level ?? LanguageLevel.Basic) : [],
    }

    return axios.default.get<AssistantListItem[]>(`${url}`, {
        params: params,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    });
}

export function getNewestAssistants() {
    return axios.default.get<AssistantListItem[]>(`${url}/newest`);
}
